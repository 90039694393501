import React, { useState } from 'react';
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    Box,
    Avatar,
} from '@mui/material';
import purple from '@mui/material/colors/purple';
import { defaultListboxReducer } from '@mui/base';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import axios from 'axios';
import util from './util';

const LoginPage = (props) => {
    // const [username, setUsername] = useState('anna');
    // const [password, setPassword] = useState('Anna!Kam2468');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const login = async () => {
        try {
            let loginResult = await axios.post(util.getUrl() + "loginUserAccount", { username: username, password: password });
            let token = loginResult.data.data;
            // Anna!Kam2468
            // console.log("token:" + token);
            if (token != null) {
                props.onLoginSuccess(token);
            } else {
                alert("invalid username or password");
            }
        } catch (err) {
            console.log("error:" + err);
        }
    }
    return (
        <Paper style={{
            padding: '20px',
            margin: '20px',
            width: "80vw"
        }}>

            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <Box sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
                        <AccountBoxIcon style={{ width: "10vw", height: "10vh", color: "#1976d2" }} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        value={password}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={login}
                    >
                        Sign in
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default LoginPage;