import React, { useState } from 'react';
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    Box,
    Avatar,
    IconButton,
    FormControl
} from '@mui/material';
import purple from '@mui/material/colors/purple';
import { defaultListboxReducer } from '@mui/base';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import axios from 'axios';
import util from './util';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
const AdminPage = (props) => {
    const [newProjectId, setNewProjectId] = useState('');
    const [addProjectInProgress, setAddProjectInProgress] = useState(false);
    const addProject = (e) => {
        console.log("Add project..." + newProjectId);
        if (newProjectId.length < 4) {
            alert("Project id must be at least 4 characters");
            return;
        }
        setAddProjectInProgress(true);
        axios.post(util.getUrl() + "getProjectInfoList", { headers: { "Auth": props.loginToken } }).then((res) => {
            let _projectList = res.data.data;
            let foundProjectInExistingList = false;
            for (let i = 0; i < _projectList.length; i++) {
                if (_projectList[i].projectID === newProjectId) {
                    foundProjectInExistingList = true;
                    break;
                }
            }
            if (foundProjectInExistingList) {
                alert("Project already exists");
                setAddProjectInProgress(false);
            } else {

                let createDate = "" + Math.floor(new Date().getTime()/1000);
                let endDate = "" + (Math.floor(new Date().getTime()/1000)+(60*60*24*30));

                axios.post(util.getUrl() + "createProjectInfo", { "projectID": newProjectId, "startDate": createDate, "endDate": endDate }, { headers: { "Auth": props.loginToken } }).then((res) => {
                    alert("ProjectID " + newProjectId + " created");
                }).finally(() => {
                    setAddProjectInProgress(false);
                });
            }
        });
    }
    return (
        <>
            <Paper style={{
                padding: '20px',
                margin: '20px',
                width: "80vw"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Box sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
                            <AccountTreeIcon style={{ width: "5vw", height: "5vh", color: "#1976d2" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <TextField fullWidth label="New Project ID" variant="outlined" value={newProjectId}
                            onChange={(e) => setNewProjectId(e.target.value)} InputProps={{
                                endAdornment: <IconButton disabled={newProjectId.length < 4 || addProjectInProgress} edge="end" color="primary" onClick={addProject}>
                                    {addProjectInProgress ? <HourglassBottomIcon /> : <LibraryAddIcon />}
                                </IconButton>
                            }} />
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={{
                padding: '20px',
                margin: '20px',
                width: "80vw"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <Button variant="outlined" onClick={() => props.setCurrentPage("download")}>Go To Data Download</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default AdminPage;