import React, {useState} from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import LoginPage from "./login";
import DownloadPage from "./downloadform"
import AdminPage from "./admin";
import * as jose from 'jose'
function App() {
  const [token, setToken] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('login');
  const [userType, setUserType] = useState('');
  const [userProjectList, setUserProjectList] = useState([]);
  const loginSuccess = async (token) => {
    // console.log("obtained:" + token);
    setToken(token);
    setLoggedIn(true);
    setCurrentPage('download');

    const payload = await jose.decodeJwt(token);
    console.log("====> userType:" + payload.data.userType);
    setUserType(payload.data.userType);
    let projectIDList = payload.data.projectIDList;
    console.log("====> projectIDList:" + projectIDList);
    setUserProjectList(projectIDList.split(","));
  }
  return (
    <div className="App">
      <header className="App-header">
        {/* {"login token:" + token}
        {"current page:" + currentPage} */}
        {!loggedIn?<LoginPage onLoginSuccess={loginSuccess}/>:<></>}
        {loggedIn&&(currentPage==="download")?<DownloadPage setCurrentPage = {setCurrentPage} loginToken = {token} userType={userType} userProjectList={userProjectList}/>:<></>}       
        {loggedIn&&(currentPage==="admin")?<AdminPage setCurrentPage = {setCurrentPage} loginToken = {token} userType={userType}/>:<></>}
        {/* {JSON.stringify(userProjectList)} */}
      </header>
    </div>
  );
}
export default App;
